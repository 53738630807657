<template>
  <v-card
    class="course"
    :color="cardColor"
    :max-width="maxWidth"
    elevation="2"
    rounded="lg"
  >
    <v-img :src="avatar" max-height="100">
      <v-container class="fill-height" v-if="isRequirementFulfilled">
        <v-row align="start">
          <v-row justify="end">
            <div class="mr-8 mt-n8">
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </div>
          </v-row>
        </v-row>
      </v-container>
    </v-img>
    <v-card-title
      style="max-width: 437px"
      class="d-inline-block text-overline text-center mb-6 mt-3"
      v-if="name"
      >{{ name }}</v-card-title
    >
    <template>
      <v-sheet
        :color="cardColor"
        v-if="allFilesFulfilled"
        class="d-flex justify-center"
      >
        <v-switch v-model="fileSwitchOn" hide-details label="Editar"></v-switch>
      </v-sheet>
    </template>
    <v-card-text class="px-4">
      <v-sheet
        class="d-flex justify-center text-h6"
        :color="cardColor"
        v-if="isFileStored"
        ><v-btn
          plain
          outlined
          rounded
          color="identity"
          :href="fileUrl"
          target="_blank"
          >{{ fileName }}
          <v-icon color="success">mdi-eye-check-outline</v-icon></v-btn
        >
      </v-sheet>
      <v-file-input
        :rules="[
          rules.verified(file, requirementById(requirementId)),
          rules.required
        ]"
        :prepend-icon="uploadIcon"
        :hide-input="!fileSwitchOn && isFileStored"
        chips
        v-model="file"
        accept="application/pdf"
        :clearable="false"
        :disabled="allFilesFulfilled && !fileSwitchOn"
        :label="label"
        :loading="loading"
        @change="onChangeFile($event, requirementById(requirementId))"
        @update:error="valid = $event"
        :success="valid"
        hint=""
        :persistent-hint="!loading"
      >
        <template v-slot:selection="{ text }">
          <v-chip
            :color="statusColor(valid)"
            class="text-button px-3 py-5"
            :class="statusCls(valid)"
            >{{ text }}
          </v-chip>
          <br />
        </template>
        <br />
        <template slot="progress">
          <br />
          <v-progress-linear
            height="25"
            v-model="uploadValue"
            color="light-green lighten-2"
            class="light-green--text text--darken-4"
            rounded
            ><template v-slot:default="{ value }">
              <strong>{{ progressPercentage(value) }}%</strong>
            </template></v-progress-linear
          >
        </template>
      </v-file-input>
      <v-snackbar v-model="snackbar" :multi-line="multiLine">
        ¡Disculpa! Parece que no tienes permisos para subir este archivo
        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-card-text>
    <v-card-actions v-if="!isFileStored" class="px-8">
      <v-container fluid class="ma-0 pa-0 mt-4">
        <v-row align="center" class="mb-2" v-if="universe" no-gutters>
          <v-col cols="6" class="text-right mr-2">
            <template>
              <v-progress-circular
                :rotate="360"
                :size="75"
                :width="15"
                :value="`${completed}%`"
                :color="semaphore"
              >
                {{ completed }}%
              </v-progress-circular>
            </template>
          </v-col>
          <v-col cols="4">
            <span class="text-subtitle-1 text--secondary text-left"
              >lo han tomado</span
            >
          </v-col>
        </v-row>
        <v-row justify="space-around" class="mt-4">
          <span class="group">
            Si aún no la tienes:
          </span>
          <span class="group">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :href="link"
                  color="identity"
                  target="_blank"
                  rel="noopener noreferrer"
                  small
                  rounded
                  outlined
                  prepend-icon="mdi-calendar"
                  >Toma el curso</v-btn
                >
              </template>
              <span>Abrir en nueva ventana</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row justify="space-around">
          <span class="group">
            <div class="mt-3 mb-2 caption">
              <v-icon x-small>mdi-clock</v-icon> Duración
              <strong> {{ hrs }} horas</strong>
            </div>
          </span>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import semaphoreColor from '@/micro/supervision/semaphoreColor'
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { getFileInfoPath } from '../../store/modules/resoursesApi/fbApi'
import { isPhone } from '@/micro/display'

export default {
  name: 'Course',

  data() {
    return {
      uploadValue: 0,
      loading: false,
      loaded: false,
      file: null,
      previousFile: null,
      fileSwitchState: false,
      fileUploaded: false,
      rules: null,
      validFile: false,
      fileUrlState: null,
      snackbar: false,
      multiLine: true
    }
  },

  props: {
    requirementId: {
      type: String,
      required: true
    },
    resourceId: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      default: require('@/assets/henkel-logo-standalone-svg.svg')
    },
    initFile: {
      type: Object
    },
    link: {
      type: String,
      required: true
    },
    hrs: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    universe: {
      type: Number,
      required: false
    },
    stats: {
      type: Object,
      required: false
    }
  },

  computed: {
    ...mapGetters('requirements', [
      'allFilesFulfilled',
      'fileSaved',
      'requirementById'
    ]),
    ...mapGetters('user', ['uid', 'context']),
    isPhone() {
      return isPhone(this.$vuetify)
    },
    maxWidth() {
      // return this.isPhone ? 344 : 284
      return 344
    },
    isFileStored() {
      return !!this.initFile || this.fileUploaded
    },
    onEdition() {
      return this.fileSwitchOn || !this.isFileStored
    },
    fileName() {
      return this.file ? this.file.name : this.initFile.name
    },
    isRequirementFulfilled() {
      return !this.loading && (this.valid || !!this.fileUrl)
    },
    cardColor() {
      return this.isRequirementFulfilled ? '#E8F5E9' : '#FAFAFA'
    },
    completed() {
      return (this.stats.verified / this.universe) * 100
    },
    semaphore() {
      return semaphoreColor(this.completed)
    },
    statusColor() {
      return valid => (valid ? 'light-green lighten-2' : 'red lighten-2')
    },
    statusCls() {
      return valid =>
        valid ? 'light-green--text text--darken-4' : 'white--text'
    },
    label() {
      return this.file ? '' : 'Anexa aquí...'
    },
    fileUrl: {
      get() {
        return this.fileUrlState
      },
      set(state) {
        this.fileUrlState = state
      }
    },
    fileSwitchOn: {
      get() {
        return this.fileSwitchState
      },
      set(state) {
        this.fileSwitchState = state
      }
    },
    uploadIcon() {
      // return !this.initFile && (this.fileSwitchOn || !this.valid)
      return this.onEdition ? 'mdi-paperclip' : ''
    },
    valid: {
      get() {
        return this.validFile
      },
      set(error) {
        this.validFile = !error
        if (!error) {
          this.previousFile = this.file
        } else {
          this.fileUploaded = false
          this.file = this.previousFile
        }
      }
    }
  },

  methods: {
    ...mapActions('requirements', ['fulfillRequirement']),
    progressPercentage(value) {
      const res = Math.ceil(value)
      return res === 100 ? 99 : res
    },
    onChangeFile(e, requirement) {
      this.snackbar = false
      let pass = this.rules.verified(e, requirement)
      if (pass === true) {
        this.uploadValue = 0
        this.loading = true
        this.onUpload()
      }
    },

    async onUpload() {
      const docID = await firebase
        .firestore()
        .collection(this.requirementInfoPath)
        .doc().id

      const storageRef = firebase
        .storage()
        .ref(`${this.filePath}/${docID}`)
        .put(this.file)

      storageRef.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {
          console.log('Error uploading...', error.message)
          this.fileUploaded = false
          this.file = null
          this.setLoaded('change')
          this.valid = false
          this.snackbar = true
        },
        async () => {
          this.fileUrl = await storageRef.snapshot.ref.getDownloadURL()
          this.saveResourceRecord(docID, this.fileUrl)
          this.setLoaded('change')
          this.uploadValue = 100
          this.fulfillRequirement({ id: this.requirementId, file: this.file })
          this.fileUploaded = true
        }
      )
    },

    /** @throws */
    async saveResourceRecord(docID, url) {
      const { lastModified, lastModifiedDate, name, size, type } = this.file
      await firebase
        .firestore()
        .collection(this.requirementInfoPath)
        .doc(docID)
        .set({
          uid: this.uid,
          requirementId: this.requirementId,
          resourceId: this.resourceId,
          uploaded: firebase.firestore.FieldValue.serverTimestamp(),
          url,
          file: {
            lastModified,
            lastModifiedDate,
            name,
            size,
            type
          }
        })

      const metaDocRef = firebase
        .firestore()
        .doc(`${this.requirementInfoPath}/meta`)
      const doc = await metaDocRef.get()
      if (doc && doc.exists) {
        metaDocRef.update({
          count: firebase.firestore.FieldValue.increment(1)
        })
      } else {
        metaDocRef.set({
          count: firebase.firestore.FieldValue.increment(1)
        })
      }
      this.loading = false
    },

    setLoaded(action) {
      if (this.file) {
        this.loaded = true
        this.loading = false
      } else if (!this.file && action == 'change') {
        this.loaded = false
        this.loading = false
      } else {
        this.loaded = false
        this.loading = true
      }
    }
  },

  created() {
    this.fileUrl = this.initFile ? this.initFile.url : null
    if (this.fileUrl) {
      this.fulfillRequirement({ id: this.requirementId, file: this.initFile })
    }
    this.requirementInfoPath = getFileInfoPath(
      this.uid,
      'covid19pass',
      this.requirementId,
      this.context
    )
    this.filePath = `${this.context.rs}/${this.context.ou}/${this.uid}/covid19pass/${this.requirementId}`
    this.rules = {
      verified: (file, requirement) => {
        if (!file) return false
        if (!requirement.verificationRequired) return true
        const ref = requirement.reference
        const validSizeDiff =
          Math.abs(file.size - ref.size) / ref.size < ref.sizeDiff
        const validation =
          validSizeDiff ||
          `La constancia ${file.name} no coincide con la requerida ${ref.name}`

        return validation
      },
      required: v => !!v || 'En necesario que agregues la constancia'
    }
  }
}
</script>

<style lang="scss">
.course .v-card__title {
  height: 60px;
}
.v-application .course .text-overline {
  line-height: 1.1rem;
}
.v-application--is-ltr .course .v-input__prepend-outer {
  margin-right: 4px;
}
.v-application--is-ltr .course .v-text-field .v-label {
  font-size: 1.2rem;
  color: $identity;
  font-weight: 400;
  text-transform: uppercase;
}
.course .v-messages__message {
  font-size: 14px;
}
</style>
